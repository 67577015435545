module.exports = {
  concerts: [
    // FORMAT:
    // {
    //   data: '',
    //   title: '',
    //   duo: '',
    //   city: '',
    //   country: '',
    //   location: '',
    //   program: '',
    //   link: '',
    // },
    // {
    //   data: '9 Sep 2021',
    //   title: 'Duo Recital with Xi Zhai',
    //   duo: 'AX pianoduo',
    //   city: 'Frankfurt',
    //   country: 'Germany',
    //   location: 'Orangerie',
    //   program: 'Schubert, Stravinsky',
    //   link: 'https://goo.gl/maps/YN3L2M7bQKawk5rU7',
    // },
    {
      data: '24 Oct 2021',
      title: 'Concert with the Malbec Klavierquartett',
      city: 'Frankfurt',
      country: 'Germany',
      location: 'Vila Cursana',
      program: 'Mozart, Dvorak',
      link: 'https://goo.gl/maps/Z7oYTKcK8VPSpYhHA',
    },
    {
      data: '29 Oct 2021',
      title: 'Duo Recital with Xi Zhai',
      city: 'Frankfurt',
      country: 'Germany',
      duo: 'AX pianoduo',
      location: 'Dr. Hoch’s Konservatorium',
      program: 'Mozart, Schubert, Ravel, Bizet-Anderson',
      link: 'https://goo.gl/maps/RQhqQPDsWZ99VvAY9',
    },
    {
      data: '30 Oct 2021',
      title: 'Concert with the Malbec Klavierquartett',
      duo: '',
      city: 'Bad Brückenau',
      country: 'Germany',
      location: 'Dorint Hotel',
      program: 'Dvorak, Strauss',
      link: 'https://goo.gl/maps/Uzkr67zYRwHaBsX4A',
    },
    {
      data: '5 Nov 2021',
      title: 'Concert with the chamber chor Main Vokal',
      duo: '',
      city: 'Frankfurt',
      country: 'Germany',
      location: 'Festeburggemeinde',
      program: '',
      link: 'https://goo.gl/maps/pRcvaCD6RCdXSaaQA',
    },
    {
      data: '28 Nov 2021',
      title:
        "A concert reading of Richard Wagner's novella 'A Pilgrimage to Beethoven' on the occasion of Beethoven's 251st birthday with Dirk Jenders and Christoph Jenisch",
      duo: '',
      city: 'Frankfurt',
      country: 'Germany',
      location: 'Dr. Hoch’s Konservatorium',
      program: 'Beethoven, Wagner',
      link: 'https://goo.gl/maps/RQhqQPDsWZ99VvAY9',
    },
    {
      data: '3 Dec 2021',
      title: 'Duo Recital with Xi Zhai',
      duo: 'AX pianoduo',
      city: 'Landshut',
      country: 'Germany',
      location: 'Rathausprunksaal',
      program: 'Tchaikovsky, Ravel, Bizet-Anderson',
      link: 'https://goo.gl/maps/wXr67iMDtN2zES5AA',
    },
    {
      data: '8 Dec 2021',
      title: 'Concert with the Malbec Klavierquartett',
      duo: '',
      city: 'Bad Homburg',
      country: 'Germany',
      location: 'Schlosskirche ',
      program: 'Strauss, Brahms',
      link: 'https://goo.gl/maps/xsotQfLxbwVQVxNY7',
    },
    {
      data: '12 Dec 2021',
      title: 'Concert with the Malbec Klavierquartett',
      duo: '',
      city: 'Leipzig',
      country: 'Germany',
      location: 'Hochschule für Musik',
      program: 'Mozart, Dvorak',
      link: 'https://goo.gl/maps/6BTZ4ka3wy1R7TGh8',
    },
    {
      data: '23 Jan 2022',
      title: 'Duo Recital with Xi Zhai',
      duo: 'AX pianoduo',
      city: 'Frankfurt',
      country: 'Germany',
      location: 'HfMDK',
      program: 'Stravinsky, Xilin Wang, Saint-Saens, Ravel, Bizet',
      link: 'https://goo.gl/maps/aD81oh251LUKhSLd6',
    },
    {
      data: '01 Oct 2023',
      title: 'Concert with Malbec Klavierquartett',
      time: '19:30',
      duo: '',
      city: 'Usingen',
      country: 'Germany',
      location: 'Usingen',
      program: 'Mozart, Turina, Dvorak',
      link: 'https://goo.gl/maps/tCEn814aqTwEtnc78?coh=178573&entry=tt',
    },
    {
      data: '26 Oct 2023',
      title: '…Of foreign Lands and Peoples',
      time: '19:00',
      duo: 'AX pianoduo',
      city: 'Frankfurt',
      country: 'Germany',
      location: 'Villa Bonn. Siesmayerstraße 12',
      program:
        'M.Moszkowski from Foreign Lands, op.23 "Russia. Germany. Spain. Poland. Italy. Hungary.", F.Schubert "Fantasie in f- Minor D940", R.Schumann “Pictures from the East” op.66, I.Stravinsky "Trois mouvements de Pétrouchka"',
      link: 'https://goo.gl/maps/tZDNo9j3XxVk5imv9?coh=178573&entry=tt',
    },
  ],
};
