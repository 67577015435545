import React from 'react';

import Layout from '../components/Layout';
// import Scroll from '../components/Scroll';

// import config from '../../config';
import Footer from '../components/Footer';
// import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

// import demoImage1 from '../assets/images/demo-image-01.jpg';
// import demoImage2 from '../assets/images/demo-image-02.jpg';

import Schedule from '../data/schedule';

const dataDesign = (data) => {
  const newData = data.split(' ');
  return {
    day: newData[0] || '',
    month: newData[1] || '',
    year: newData[2] || '',
  };
}

const IndexPage = () => (
  <Layout>
    <Header />

    <section id="schedule" className="main-bg">
      <div className="container">
        <div className="schedule-section">
          <h2 className="text-about mb-4">Schedule</h2>
          {Schedule.concerts.reverse().map(concert => {
            const { data, title, time, duo, city, country, location, program, link } = concert;
            const keyID = data + "__" + title;
            const concertData = dataDesign(data);

              return (
                <div className="row concert" key={keyID}>
                  <div className="col-12 col-md concert__data">
                    <div className="concert__day">{concertData.day} {concertData.month}</div>
                    <div className="concert__year">{concertData.year}</div>
                  </div>
                  <div className="col-12 col-md concert__content">
                    <div className="concert__title">{title}</div>
                    <div className="concert__duo">{duo}</div>
                    {time && <div className="concert__time">{time}</div>}
                    <div className="concert__program">Program: {program}</div>
                    <div className="concert__city">Location:
                      <span role="img" aria-labelledby="location">📍</span>
                      <a href={link} rel="noreferrer" target="_blank">{country}, {city}, {location}</a>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
